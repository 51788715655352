<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation style="max-width: 800px">
                <v-row>
                    <v-col cols="12">
                        <label class="v-label v-label--control">Oppsummering</label>
                        <redactor v-model="form.oppsummering"></redactor>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-alert border="left" colored-border color="primary" elevation="1"> Register hvem som deltok under deltakere fanen. </v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="form.id" color="primary" @click="save">{{ $t('btn.save') }}</v-btn>
            <v-btn v-if="changed && !saving" text color="primary" v-on:click="cancel">Angre endringer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import Redactor from '@/vendor/noop/components/Redactor.vue';

export default {
    name: 'ActivityTabGeneral',
    components: {
        Redactor,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            valid: true,
            changed: null,
            saving: false,

            sorgGruppeId: null,
            moteId: null,
            oppsummering: null,

            form: {
                oppsummering: '',
            },
        };
    },
    watch: {
        'form.oppsummering': function () {
            this.changed = true;
        },
    },

    /**
     * created
     */
    created: async function () {
        if (this.value) {
            this.sorgGruppeId = this.value.referanseId;
            this.moteId = this.value.id;

            await this.update();
        }
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * update
         */
        update: async function () {
            let response = await this.request({
                method: 'get',
                url: '/sorggruppe/' + this.sorgGruppeId + '/mote/' + this.moteId + '/oppsummering',
            });

            if (response && response.id) {
                this.oppsummering = response;
            }

            this.form = Object.assign({}, this.oppsummering);
            this.changed = false;
            this.saving = false;

            window.setTimeout(() => {
                this.valid = true;
                this.changed = false;
                this.saving = false;
            }, 200);
        },

        /**
         * save
         */
        save: async function () {
            if (this.$refs.form.validate()) {
                this.saving = true;

                const data = new FormData();
                data.append('oppsummering', this.form.oppsummering);

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/' + this.sorgGruppeId + '/mote/' + this.moteId + '/oppsummering',
                    data: data,
                });

                if (response && typeof response.id != 'undefined') {
                    if (this.form.id) {
                        this.snackbar('Lagret');
                        this.update();
                    }
                }
            }
        },

        /*
         * cancel
         */
        cancel: function () {
            if (this.value) {
                this.form = Object.assign({}, this.oppsummering);
                this.changed = false;
                this.update();
            }
        },
    },
};
</script>
